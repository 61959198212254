.changePeriodButtonSelected {
    background-color: rgba(70, 204, 40, 29%);
    width: 160px;
    height: 50px;
    border-radius: 30px;
    color: black;
    border-color: #42AB42;
    border-style: solid;
    font-size: 17px;
    font-style: 'Roboto';
    font-weight: 500;
    border-width: 1.5px;
    float:left;
    margin-right:20px;
    transition: box-shadow .3s;
    margin-left: 20;
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.changePeriodButtonSelected:hover {
    background-color: rgba(70, 204, 40, 29%);
    width: 160px;
    height: 50px;
    border-radius: 30px;
    color: black;
    border-color: #42AB42;
    border-style: solid;
    font-size: 17px;
    font-style: 'Roboto';
    font-weight: 500;
    border-width: 1.5px;
    float:left;
    margin-right:20px;
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
    transition: box-shadow .3s;
}

.changePeriodButtonDisabled {
    background-color: white;
    width: 160px;
    height: 50px;
    border-radius: 30px;
    color: #277127;
    border-color: rgba(218, 201, 201, 100%);
    border-style: solid;
    font-size: 17px;
    font-style: 'Roboto';
    font-weight: 500;
    border-width: 1.5px;
    float:left;
    margin-right:20px;
    transition: box-shadow .3s;
    
}

.changePeriodButtonDisabled:hover {
    background-color: white;
    width: 160px;
    height: 50px;
    border-radius: 30px;
    color: #277127;
    border-color: rgba(218, 201, 201, 100%);
    border-style: solid;
    font-size: 17px;
    font-style: 'Roboto';
    font-weight: 500;
    border-width: 1.5px;
    float:left;
    margin-right:20px;
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
    transition: box-shadow .3s
}

.divButton {
    margin-left: 20px;
}