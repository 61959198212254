.generalTextArea {
    text-align: center;
}

.userEmail {
    color: rgb(4, 112, 4);
    font-style: normal;
}

.letterIcon {
    height: 200px;
}

#backToHomeLink {
    text-decoration: none;
}