.ChartTableTitle {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.ChartTable {
    text-align: center;
    font-size: 20px;
    font-size: 30px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.769);
}

.increasedPercentage {
    color: red;
    font-style: normal;
}

.decreasedPercentage {
    color: blue;
    font-style: normal;
}

.samePercentage {
    color: green;
    font-style: normal;
}


