/* #emailField, #nameField, #suggestion {
    background-color: #37F418;
    background-color: rgba(55,244,24, 0.36);
    border-radius: 50px;
    font-size: 20px;
    color: black;
} */

#subInformation {
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.562);
}

#submitCurrencyButton {
    width: 100%;
}

/* .sectionTitle {
    font-size: 22px;
    margin-top: 10px;
} */



.formContainer {
    height: 460px;
    text-align: center;
    color: black;
}

/* .formContainerErrors {
    background-color: #FFFFFF;
    height: 520px;
    border-radius: 15px;
    text-align: center;
}


#lampIcon {
    height: 45px;
    width: 45px;
    opacity: 60%;
}

.divingBar {
    font-size: 22px;
    margin-top: 10px;
    margin-left: 10px;
    opacity: 20%;
}

.redirecting {
    font-size: 15px;
    margin-top: 15px;
    margin-left: 10px;
    opacity: 90%;
    text-decoration: none;
    color: black;
} */

.generalInput {
    border: 0;
    width: 80%;
    display: inline-block;
    height: 50px;
    text-indent: 20px;
    margin-bottom: 35px;
    border-color: rgb(red, green, blue);
    box-shadow: 0 0 2px #299e29;
    padding: 20px;
}

/* .generalInput:focus {
    border: 0;
    border-color: red;
    width: 80%;
    display: inline-block;
    height: 50px;
    text-indent: 20px;
} */

/* ::placeholder {
    color: #595959;

} */

.sendSuggestionButton {
    background-color: #4FE84F;
    text-align: right;
    height: 50px;
    width: 200px;
    color: white;
    text-decoration: none;
    border: 0;
    border-radius: 5px;
    transition: 0.3s;
    margin-top: -20px;

}

.sendSuggestionButton:hover {
    background-color: #46CC46;
    text-align: center;
    height: 50px;
    width: 200px;
    color: white;
    text-decoration: none;
    border: 0;
    border-radius: 5px;
    transition: 0.3s;
}

.shortDescription {
    font-size: 14px;
    opacity: 30%;
    font-weight: 500;
    display: inline-block;
    text-indent: 20px;
    width: 80%;
}

/* input:focus {
    outline: none !important;
    border:1px solid red;
    box-shadow: 0 0 2px #46CC46;
  
} */

.errorMessage {
    color: rgba(255, 0, 0, 0.815);
    text-align: left;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    text-indent: 20px;
    width: 80%;
}

hr {
    opacity: 10%;
}

.FormArea {
    text-align: left;
    width: 50%;
    margin-left: 50vh;
    padding: -20px;
}

.defaultLabel {
    text-align: left;
}

.signNewsletterButton {
    text-align: left;
}

#sendSuggestionSubTitle {
    font-weight: lighter;
    color: rgb(122, 120, 120);
}