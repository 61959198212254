#show_chart_from_currency {
    width: 180px;
    font-size: 15px;
    text-align: center;
}

#show_chart_from_currency_chart {
    color: rgba(0, 0, 0, 0.527);
    font-weight: 400;
}

.increasedPercentage {
    color: red;
    font-style: normal;
}

.decreasedPercentage {
    color: blue;
    font-style: normal;
}

.samePercentage {
    color: green;
    font-style: normal;
}

.arrowIcon {
    margin-left: 7px;
    color: rgba(5, 5, 5, 0.342);
}

.reportTitle {
    font-style: normal;
    font-size: 30px;
    font-weight: 400;
}

.currencyName {
    font-style: normal;
    font-size: 30px;
    margin-left: 5px;
}