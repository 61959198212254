#developedBy {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
}

#githubRedirect {
    text-decoration: none;
    font-weight: 400;
    color: black;
    font-size: 15px;
}

*:focus {
    outline: none;
}