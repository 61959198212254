#navbarArea {
    background-color: #46cc46;
}

#navLink {
    color: rgba(255, 251, 251, 0.432);
}

#iconsNavbar {
    color: rgba(255, 251, 251, 0.432);
}

