.modal-content {
    border-radius: 12px;
}

.generalInput {
    border: 0;
    width: 80%;
    display: inline-block;
    height: 50px;
    text-indent: 20px;
    margin-bottom: 35px;
    border-color: rgb(red, green, blue);
    box-shadow: 0 0 2px #299e29;
    padding: 20px;
}