#searchCurrencyInput {
    width: 100%;
    border-radius: 20px;
    font-size: 30px;
    outline: none;
    border-width: 1px;
    border-color: #46cc46;;
    text-align: center;
}

#foundCurrency {
    border-radius: 50px;
    height: 200px;
    text-align: center;
}

.hidden {
    display: none;
}