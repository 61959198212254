#calculatorButton {
    width: 100%;
}

.resultCalculation {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    height: 100px;
    transition: all 400ms ease;  
    font-weight: 400;
    font-size: 25px;
}

.resultCalculation:hover {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition: all 400ms ease; 
}

.hidden {
    visibility: none;
    transition: all 400ms ease;  
}


.inputCalculator {
    width: 100%;
    border-radius: 10px;
    font-size: 20px;
}

#inputLabels {
    font-weight: 400;
    margin-bottom: 20px;
}

#calculateButton {
    border-radius: 10px;
    width: 100%;
    background-color: #007BFF;
    border: 0;
    font-weight: 500;
}

.calculateButton {
    width: 100%;
    background-color: #46CC46;
    border-radius: 20px;
}

.resultArea {
    color: #1C8D1C;
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
}